@import "../../styles/branding.scss";
.settings-container {
  display: flex;
  flex-direction: column;
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: -15px;
    h3 {
      font-size: 20px;
      font-weight: 700;
    }
  }
  .btn-with-icon {
    margin-top: 20px;
  }
  section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid $gray-400;
    padding: 30px 0px;
    h4 {
      font-size: 18px;
      font-weight: 600;
      color: $theme-Sapphire;
      margin-bottom: 22px;
    }
    h5 {
      font-size: 16px;
      color: $body-black;
      margin-bottom: 18px;
      font-weight: 700;
    }

    h6 {
      font-size: 14px;
      color: $gray-600;
      font-weight: 400;
    }
    .settings-form-item {
      display: flex;
      label {
        font-weight: 400 !important;
        font-size: 16px !important;
        margin-bottom: 0;
      }
    }
    .settings-form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 18px;
      & > .settings-form-item:not(:first-child) {
        margin-top: 5px;
      }
    }
    .settings-check-box-label {
      margin-top: 18px;
    }
    .form-label {
      margin-left: 8px;
    }
    .sub-section-item {
      margin-top: 23px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .settings-check-box-label .custom-switch {
        display: flex;
        align-items: center;
      }
    }

    .select-label-wrapper {
      .Select-option{
        background-color: transparent;
        &:hover{
          background-color: $gray-200;
        }
      }
      .Select-option.is-selected {
        background-color: $navy;
        color: white;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 8px;
      h5 {
        margin-bottom: 5px;
      }
      & > div {
        display: flex;
        margin-top: 8px;
        align-items: baseline;
        .Select {
          margin-inline: 15px;
          min-width: 100px;
        }
      }
      .completion-form {
        margin-right: 10px;
      }
    }
    .completion-wrapper {
      margin-top: 18px;
      .Select {
        min-width: 80px !important;
      }
    }
    .select-with-label {
      display: flex;
      text-align: justify;
      align-items: center;
      p {
        font-size: 16px;
      }
      .Select {
        margin-left: 15px;
        width: 210px;
      }
    }
  }
  section {
    &:last-child {
      border-bottom: none;
    }
  }
}
.automatic-reminders-wrapper {
  padding-bottom: 30px !important;
  margin-bottom: -5px;
  h4 {
    margin-bottom: 5px !important;
  }
}
.engagement-type-wrapper
{
  padding-bottom: 0px !important;
  margin-bottom: -5px;
  .message-list-box {
    width: 20%;
  }
}
.engagemen-type-wrapper
{
  width:100%;
}

.system-default-engagement-type {
  border-left: 0px !important;
  width: 53px !important;
  margin: 0px 8px !important;
}

.row-padding-engagement-type{
  padding: 8px 3px !important;
}

.Select.is-disabled > .Select-control {
  background-color: #f0f1f1 !important;
  cursor: not-allowed !important;
}

.engagement-category-dropdown {

  & > div {
    border: 1px solid #898d91 !important;
    color: var(--bs-body-color);
  }

  .Select-placeholder {
    color: #565A5E;
  }

  .Select-control {
    cursor: pointer;
  }

  .Select-option{
    background-color: transparent;
    &:hover{
      background-color: $gray-200;
    }
    &.is-selected{
      background-color: $navy;
      color: white;
    }
  }
}

.engagement-type-list-box
{
  display : flex;
  width:100% !important;
  max-width: 532px ;
}
.message-list-box {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 34px;
 
  & > div {
    width: 100%;
    min-height: 125px;
    overflow-y: auto;
    max-height: 240px;
    border-radius: 0px 0px 3.2px 3.2px;
    border: 1px solid $gray-400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    .message-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      .engagementtype-max-width
      {
        text-overflow: ellipsis;
        max-width: 6rem;
      }  
      .diplay-flex-div{
        display: flex;
      }
      &:hover {
        background-color: $sapphire-100;
      }
      p {
        margin-left: 9px;
        display: flex;
      }
      .no-border-icon{
        border:none ;
        padding: 0px !important;
        width: 53px;
        margin: 0px 8px;
        color: $navy !important;
        // margin-top: 0px !important;
        background-color:unset !important;
        &:nth-child(n+2):last-child{
          border-left: 1px solid rgba(33, 37, 41, 0.25);
          width : 100px;
          margin-right: 0px;     
        }
        svg{
          path{
            fill: $navy !important;
          }
        }
      }
    
      span {
        background-color: $gray-100;
        border: 1px solid $body-black;
        padding-inline: 8px;
        border-radius: 4px;
        margin-right: 5px;
        margin-left: 5px;
        font-weight: 400;
      }
    }
    .active-msg {
      background-color: $sapphire-100 !important;
    }

    & > .message-wrapper {
      border-bottom: 1px solid $gray-300;
    }
  }
}
.message-box-wrapper {
  width: 36%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 81px;
  header {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  & > div {
    width: 100%;
    border-radius: 0px 0px 3.2px 3.2px;
    border: 1px solid $gray-400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 300px;
    overflow-y: auto;
    padding: 5px 24px 15px 11px;
    .item-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 9px;
      width: 100%;
      & > p {
        font-size: 14px;
        padding: 7px 13px;
        background-color: $gray-100;
        border: 1px solid $gray-400;
        color: $gray-400;
        border-radius: 3.2px;
        width: 100%;
        text-align: left;
        font-style: italic;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 7px;
      }
    }
    .body-field {
      & > p {
        height: 73px;
        overflow-y: auto;
        white-space: initial;
      }
    }
  }
  footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 21px;
    .delete-icon {
      margin-left: 11px;
    }
  }
}
.add-edit-modal {
  .form-item-group {
    position: relative;
    .form-label {
      font-weight: 600;
      margin-bottom: 5px;
    }
    input {
      margin-bottom: 16px;
    }
    textarea {
      overflow-y: auto;
    }
  }
  .modal-body {
    position: static;
  }
  .settings-form-item {
    display: flex;
    .form-label {
      margin-left: 8px;
      font-weight: 400;
      font-size: 16px;
    }
  }
  .tiny-mce-wrapper {
    position: relative;
    .text-danger {
      bottom: -12%;
    }
  }
  .tiny-mce-wrapper-default{
    cursor: not-allowed;
    position: relative;
    .text-danger {
      bottom: -12%;
    }
  }
  .user-checkbox {
    margin-top: 22px;
  }
  .default-checkbox {
    position: absolute;
    bottom: 16px;
    left: 15px;
    .form-label {
      margin-bottom: 0;
      font-weight: 400;
      color: $gray-900;
    }
  }
}
.settings-accordion {
  margin-top: 22px;
  .accordion-button {
    color: $theme-Sapphire;
    background-color: white !important;
    width: auto;
    padding: 0 !important;
    &::after {
      display: none;
    }
    &:active {
      transform: none;
    }
    button {
      border: none;
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
    }
  }
  ul {
    padding-left: 0;
    margin-top: 10px;
    max-height: 20vh;
    overflow: auto;
  }
  .variable-item-wrapper {
    display: flex;
    margin-top: 4px;
  }
  li {
    list-style: none;
    position: relative;
    span {
      margin-left: 4px;
      position: absolute;
      top: -2px;
      cursor: pointer;
    }
  }
  .main-text {
    font-weight: 600;
    font-size: 14px;
    width: 185px;
  }
  .help-text {
    color: $gray-500;
    font-size: 16px;
    font-style: italic;
  }
}
#ReminderDropDown {
  width: 170px !important;
}
.tooltip > .tooltip-inner {
  max-width: none;
}
.rich-body-preview > p {
  font-style: unset !important;
  & > p{
    white-space: initial;
  }
}
.restricted-access {
  text-align: left;

  .marB15 {
    margin: 10px;
  }

  .fontfamily-inital-heading {
    font-size: 24px;
    font-weight: bolder;
  }

  .fontfamily-inital-instruction {
    font-size: 12px;
    font-weight: bolder;
  }

  .fontfamily-inital-login {
    text-decoration: underline !important;
    color: rgb(13, 110, 253) !important;
    margin-left: 10px;
    font-size: 12px;
    cursor: pointer;
  }
}
.margin-bottom-auto{
  margin-bottom: auto !important;
}


.popup-engagement-type-modal {
  .modal-dialog{
    max-width: 711px;
    max-height: 330px;
  }
  .modal-header {
    background-color: #fff;
  }
  .modal-title h4{
    font-weight: bold;
    color: black;
  }

  .modal-content {
    position: static;
    .modal-body {
      overflow: visible !important;
    }
  }

  .settings-form-item {
    display: flex;
    .form-label {
      margin-left: 8px;
      font-weight: 400;
      font-size: 16px;
    }
  }
  .user-checkbox {
    margin-top: 22px;
  }
  .default-checkbox {
    position: absolute;
    bottom: -40px;
    left: 15px;
    .form-label {
      margin-bottom: 0;
      font-weight: 400;
      color: $gray-900;
    }
  }
}

.color-danger {
  color: #cc4a43;
  font-size: 14px;
  font-weight: 400;
}

.engagement-type-addedit-modal{
  margin-bottom: 10px;
  .text-danger{
    margin-bottom: 159px !important;
  }
}
.delete-message-margin{
  margin-top: 20px;
}

.engagement-type-addedit-value-width{
  max-width: 418px;
}


