@import "../../../../styles/branding.scss";
.my-settings-wrapper {
  display: flex;
  flex-direction: column;
  main {
    display: flex;
    justify-content: space-between;
    .left-wrapper {
      width: 45%;
      display: flex;
      flex-direction: column;

      .download-options-wrapper {
        margin-bottom: 10px;
      }

      .label-with-radio-inline {
        display: flex;
        margin-top: 24px;
        align-items: center;

        &>label {
          font-weight: 600;
          margin-right: 14px;
          font-size: 14px;
          margin-bottom: 0 !important;
        }

        .form-check-inline {
          display: inline-flex;
          margin-right: 10px;

          label {
            margin-left: 4px;
            font-size: 14px;
          }
        }
      }
    }

    .right-wrapper {
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .dropdown-label-wrapper.default-height {
        padding-bottom: 29px;
        transition: height 0.3s ease;
      }

      .dropdown-label-wrapper.expanded-height {
        padding-bottom: 65px;
        transition: height 0.3s ease;
      }
    }
    h4 {
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 15px;
      margin-top: 6px;
    }
  }

  .dropdown-label-wrapper {
    .Select-option{
      background-color: transparent;
      &:hover{
        background-color: $gray-200;
      }
    }
    .Select-option.is-selected {
      background-color: $navy;
      color: white;
    }
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    position: relative;
    .ss-select .Select-control
    .Select-multi-value-wrapper{
      width: 220px;
    }

        .custom-tab-dropdown {
          .dropdown-container {
            min-height: 29px;
          }
    
          .select-label {
            font-size: 14px;
            font-weight: 300;
            min-height: 29px;
            padding: 4px 4px 0 4px;
          }
    
          .clear-value {
            top: 15px;
          }
    
          .dropdown-icon-button {
            top: 15px;
          }
          .dropdown-box {
            border: 1px solid $gray-400;
        }
      }

    .user-list-data {
      .user-list-item {
          svg path {
            fill: $gray-600;
        }
      }

      .user-label {
        color: $gray-600;
      }
    }

  }

  .label-data {
    font-weight: 600;
    font-size: 14px;
  }
}