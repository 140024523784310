
.custom-switch{
    display: flex;
    align-items: flex-start;
    font-size: 14px;
 
   label {
    color: #101820;
    font-size: 14px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-left: 10px !important;
    font-weight: bold;
   }
   .form-check-input{
    width: 28px;
    height: 14px;
   }
   .form-check-input:checked {
    background-color: #0973ba;
    border-color: #0973ba;
   }
    .form-check-input:disabled {
     cursor: not-allowed;
     pointer-events: auto;
    }
}