@import "../../../../styles/branding.scss";

.my-account-modal .modal-dialog {
  height: 90%;
  max-width: 933px;
}
.my-account-modal {
  max-height: unset;
  height: calc(100vh - 185px);

  .tab-content {
    max-height: 100%;
    overflow: auto;
  }
}
.heading-blue-1 {
  color: $theme-Sapphire;
  font-size: 20px;
  font-weight: 500;
}

.heading-blue-2 {
  color: $theme-Sapphire !important;
  font-size: 14px;
  font-weight: 600;
  white-space: wrap;
  padding-right: 5px;
}

.text-gray-description {
  color: $gray-600;
  font-size: 14px;
  margin-bottom: 1rem !important;
}

#profile-form {
  .react-tel-input.error .flag-dropdown {
    border: 1px solid $error;
  }

  .flag-dropdown {
    border-radius: 5px 0 0 5px;
    background-color: white;
  }
  .react-tel-input .selected-flag .arrow {
    height: 6px;
    width: 6px;
    border: solid black;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: 2px;
    left: 26px;
    margin-top: -2px;
  }

  .react-tel-input .form-control {
    padding-left: 58px;
  }

  .react-tel-input .selected-flag {
    width: 50px;
  }
}

.my-account {
  height: 100%;

  .side-bar {
    background-color: $navy-100;
    font-size: 16px;

    .nav-item {
      width: 100%;
    }

    .nav-link {
      border-left: 3px solid transparent;
      color: $body-black;
      font-weight: 400;
      padding-left: 13px;

      &.active {
        background-color: white;
        border-left: 3px solid $theme-accent;
        font-weight: 700;
      }
    }
  }

  .input-error-message {
    color:#CC4A43;
  }

  .tab-content {
    padding: 20px 12px 0px 12px;
    //   height: calc(100vh - 300px);
  }

  .tab-pane {
    height: 100%;

    & > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    #profile-form {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .verification-container {
    border-radius: 4px;
    padding: 10px 20px;
    border: 1px solid $gray-200;
    border-left: 4px solid $info-500;

    .verification-description {
      font-size: 16px;
    }

    .input-wrapper {
      display: flex;
      line-height: 1;

      button {
        background-color: #6b7075;
      }
    }

    button {
      font-size: 14px;
    }

    .form-control {
      width: 140px;
      margin-right: 10px;
    }
  }
}

.optional-label {
  right: 12px;
  position: absolute;
  top: 5px;
  font-size: 12px;
  color: $gray-500;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
}
.optional-text{
  font-size: 12px;
  font-weight: 400;
  color: $gray-500;
  position: absolute;
  right: 0;
  bottom: 4px;
}
.my-account-modal .modal-content {
  height: 100%;
  max-height: 623px;
  overflow: hidden;
}

.fa-exclamation-triangle::before {
  content: "\f071";
}
.profile-footer {
  margin-top: auto;
  padding-bottom: 20px;
  position: relative;
  .ss-btn-secondary
  {
    &:hover {
      border:1px solid #898d91 !important;
    }      
  }
}

// LoginHistoryModal Style
.login-history-modal{
  .modal-dialog{
   display: flex;
   justify-content: center;
  }
 .modal-content{
    width: 815px !important;
    height: 561px !important;
    border-radius: 4.8px !important;
    position: relative; 

    .modal-header{
      height: 45px;
      background-color: $navy-500;
      color:$primaryColor;
      font-size: 20px;
      font-weight: 500;
      font-family: 'Mulish', sans-serif;
      border-top-left-radius: 4.8px;
      border-top-right-radius: 4.8px;
     }
    
     .modal-body{
      width: 815px;
      height: 561px;
      padding-top: 0px;
     }

     .form-check-input{
      width: 32px;
      height: 16px;
     }

     .login-history{
      .react-bs-container-body{
        display: flex;
        max-height: 340px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: -16px;
      }
    
    }

    .last-login-pagination{
      margin-top: 13px;
      height: 35px;
      align-items: center;
      align-content: center;
      justify-content: flex-end;
      position: absolute; 
      bottom: 0; 
      left: 0;
      right: 0;
      padding-right: 7px;
      padding-bottom: 15px;
    }
    
 }

}
.profile-mobile-number{
  width: 428px !important;
  .react-tel-input{
    border:1px solid #dee2e6;
    .flag-dropdown{
      border-right :1px solid #dee2e6 !important;
    }
  }
}
.flex-fill{
  max-width: 284px !important;
}
.profile-fax-number{
  width: 247px !important;
}

//LastLoginModal Styles
.last-login{

  .last-login-row{
    margin-right: 10px;
  }
  .modal-content{
    width: 500px;
    min-height: 262px;

    .modal-header{
      background-color: $navy-500;
      color: $primaryColor;
      font-size: 20px;
      justify-content: space-between;
      padding: 10px 16px;
      border-bottom: none;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}

.prompt{
  font-weight: 400;
  font-size: 16px;
}

