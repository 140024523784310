@import "../../../../styles/branding.scss";

.form-builder-container .btn-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 20px !important;
  color: #fff;
}

.form-builder-container .btn-with-icon > svg {
  margin-right: 8px;
}

.form-builder-container button {
  font-size: 16px;
  font-weight: 400;
  padding: 6px 12px !important;
}

.form-builder-container .cq-btn-primary {
  background-color: $theme-Sapphire;
  color: #fff !important;
  border: none;
}
.form-builder-container .preview-button {
  height: 38px;
  margin-right: 10px;
}
.form-builder-container .preview-button:hover,
.form-builder-container .restart-button:hover,
.form-builder-container .preview-button:active,
.form-builder-container .restart-button:active {
  border-color: $sapphire-700 !important;
  font-weight: 400;
  box-shadow: 0px 0px 1px 2px $sapphire-700;
  text-decoration: underline;
}
.form-builder-container .add-question-btn:hover svg path {
  fill: white !important;
}

.form-builder-container .btn-outline-secondary {
  background-color: transparent;
  border-color: $gray-500;
  color: $body-black !important;
  border: hidden;
  box-shadow: none;
}

.form-builder-container .btn-outline-secondary:hover {
  background-color: unset !important;
  text-decoration: underline;
}

.form-builder-container .btn-outline-secondary:focus {
  box-shadow: none;
}

.form-builder-container .btn-outline-secondary:active {
  background-color: unset !important;
}

.form-builder-container .section-dropdown .cq-btn-primary:hover {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.form-builder-container .section-dropdown .cq-btn-primary:hover svg path {
  fill: $gray-500 !important;
}
.form-builder-container .header-sec-button {
  border: 1px solid $gray-500;
  border-radius: 2px;
}
