@import "../../../../../../styles/branding.scss";
.section-container {
  margin-top: 55px;
  background-color: white;
  position: relative;
  padding: 25px 42px 15px;
  color: #212529;
}
.section-container > span {
  position: absolute;
  top: -40px;
  left: 0;
  background: rgba(107, 112, 117, 1);
  color: white;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 4px;
  max-width: 150px;
}
.section-container .toggle-button-wrapper {
  position: absolute;
  top: 10px;
  right: 18px;
  cursor: pointer;
}
.section-container .reorder-icon-wrapper {
  cursor: grab;
  position: absolute;
  left: -30px;
  top: 45%;
}
.section-container main:first-of-type {
  margin-top: 0;
}
.section-dropdown button {
  background-color: white !important;
  box-shadow: none !important;
  border: 0;
  padding: 6px 8px !important;
}
.section-dropdown .dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 16px;
  width: 170px;
}
.section-dropdown .dropdown-menu .dropdown-item:last-child {
  color: rgba(204, 74, 67, 1);
}
.section-container > footer {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
}
.section-container > footer > .section-buttons {
  display: flex;
}
.section-container > footer .dropdown-toggle::after {
  display: none;
}

.section-container > footer > .section-buttons > button:last-child {
  margin-left: 16px;
  border: none;
  background-color: white;
}
.collapsed-section-wrapper {
  padding: 1%;
}
.section-container > .collapsed-view-footer {
  margin-top: 0;
}

.section-container > .collapsed-view-footer .section-dropdown {
  position: absolute;
  right: 0;
  bottom: 15px;
  button{
    background-color: transparent !important;
  }
}
.collapsed-section-wrapper > .reorder-icon-wrapper {
  top: 30%;
}
.collapsed-section-wrapper > p {
  font-weight: bold;
  margin-bottom: 0;
}
.collapsed-section-wrapper > p :first-child {
  color: $theme-Sapphire;
  margin-right: 12px;
  font-size: 20px;
}
