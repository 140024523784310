@import '~bootstrap/scss/bootstrap';
@import './styles/branding.scss';

.App {
  text-align: center;
}

.modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;

}


.table thead th {
  border-bottom: 2px solid $gray-300 !important;
}

td {
  vertical-align: middle !important;
}

.progress {
  height: 12px !important;
}

.progress-bar {
  background-color: $shamrock !important;
}

[data-resource-id].disable {
  cursor: not-allowed !important;
  pointer-events: all;
  opacity: 0.65;
}

.loading-text {
  margin-top: 50px;
}

.w-37 {
  width: 37%;
}

.body-color {
  color: var(--bs-body-color) !important;
}