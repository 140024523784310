@import "../../../../../../styles/branding.scss";

.answer-wrapper {
    margin-top: 18px;
}
.multi-choice-preview-wrapper .form-check label {
    font-size: 16px;
    margin-left: 24px;
    font-weight: 400;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
}
.multi-choice-preview-wrapper .form-check {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    padding-left: 0;
}
.multi-choice-preview-wrapper .form-check input {
    margin: 0;
    cursor: pointer;
    width: 16px;
    height: 16px;
}
.multi-choice-preview-wrapper .error-radio input {
    accent-color: $error !important;
    visibility: hidden;
}
.multi-choice-preview-wrapper .error-radio input::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid $error;
    visibility: visible;
}
.multi-choice-preview-wrapper .error-radio label {
    color: $error !important;
}
.multi-choice-preview-wrapper .form-check:last-child {
    margin-bottom: 0;
}
.multi-choice-preview-wrapper .error-radio:nth-last-child(2) {
    margin-bottom: 0;
}
.multi-choice-preview-wrapper .error-text{
    bottom: -34px !important;
}
.sub-question label .cq-error-red {
    color: $error !important;
}
.sub-question-question-border {
    position: relative;
}
.sub-question-question-border:before {
    content: "";
    width: 1px;
    height: 71px;
    background: #0973ba;
    position: absolute;
    left: -24px;
}
.yes-no-followup-wrapper > label {
    margin-top:  30px;
    position: relative;
    margin-bottom: 0;

}
.yes-no-followup-wrapper > label:first-child:before {
    content: "";
    width: 1px;
    height: 10px;
    transform: rotate(90deg);
    background: #0973ba;
    position: absolute;
    left: -19px;
    top: 10px;
}
.followup-question-wrapper {
    margin-top: 18px;
    margin-left: 10px;
}
.yes-no-preview-wrapper{
    position: relative;
    text-align: left;
}
.yes-no-preview-wrapper .error-text{
    margin-bottom: 0;
    bottom: -24px;
}