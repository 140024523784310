@import "../../../styles/branding.scss";
.alert-wrapper {
  color: $body-black;
  display: flex;
  padding: 16px;
  border-radius: 4px;
  font-size: 16px;
  border-left: 5px solid $theme-Sapphire;
  background-color: $sapphire-100;
  margin-bottom: 30px;
  align-items: center;
  position: relative;
  & > svg {
    margin-right: 8px;
  }
  span {
    width: 95%;
    color: $body-black;
  }
p{
  color: $body-black;
}
  .cross-icon-wrapper {
    cursor: pointer;
    position: absolute;
    right: 20px;
  }
}
.inline-alert {
  display: inline-flex;
  margin-bottom: 0;
}
.alert {
  &-success {
    background-color: $success-100;
    border-left-color: $success-600;
  }
  &-warning {
    background-color: $warning-100;
    border-left-color: $warning;
  }
  &-error {
    background-color: $error-100;
    border-left-color: $error;
  }
  &-info {
    background-color: $info-100;
    border-left-color: $info;
  }
}
