#product-access-denied-btn {
    border-radius: 4px !important;
    background-color: #0973BA;
    cursor: pointer;
    border: 1px;
    width: 22rem;
    height: 3.5rem;
    padding: 0.5rem !important;
    font-size: 1.6rem;
}

.product-access-denied {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    align-self: center;
}

.product-access-denied-text {
    color: #212529;
    height: 10rem;
    width: 100rem;
    font-size: 1.8rem;
}

.product-access-denied-text>div {
    font-weight: 400;
}

.product-access-denied-text>h5 {
    font-weight: 700;
}