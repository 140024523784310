@import '../../../styles/branding.scss';
@import '../../../styles/mixins.scss';

.scrollable-loader-wrapper{
    width: 100%;
    position: relative;
}
.loader-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(250, 250, 250, 0.55);
    pointer-events: all;
    z-index: 1000;
    .loader-inner-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .loader-scrollable-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        padding: 20% 0%;
        box-sizing: border-box;
    }

    .loader-container {
        background-color: rgba(255, 255, 255, 1);
        border-radius: 4px;
        position: fixed;
        padding: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .loader-container-transparent {
        @extend .loader-container;
        background-color: transparent;
    }

    .spinner-border {
        color: $navy !important;
        width: 36px;
        height: 36px;
    }

    .status-label {
        padding: 5px 0px 5px 8px;
        color: $body-black;
        font-size: 16px;
    }
}
.modal-loader-overlay{
    z-index: 1150;
}
.page-loader-overlay{
    z-index: 1000;
}