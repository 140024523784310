@import "../../../styles/branding.scss";
.client-settings {
  section {
    h4 {
      margin-bottom: 9px !important;
    }
 
    .message-item-container {
      display: flex;
      width: 100%;
    }
    .reminder-list-wrapper{
      margin-top: 34px;
      .message-list-box{
        margin-top: 0;
      }
    }
    .accordion{
      margin-top: 15px;
    }
    .select-with-label{
      margin-top: 20px;
      .Select-control{
        width: 250px;
        .Select-multi-value-wrapper{
          width:200px;
        }
      }
      .Select-menu-outer{
        width: 250px !important;
        .Select-option{
          background-color: transparent;
          &:hover{
            background-color: $gray-200;
          }
        }
        .Select-option.is-selected {
          background-color: $navy;
          color: white;
        }
      }
    }
  }
  .border-bottom-0{
    border-bottom: none;
  }
}