@media (min-width: 768px) {
    .rolechange-notification-modal .modal-dialog {
        max-width: 580px;
    }
}

.user-autologout-modal-title {
    color: rgb(32,37,41);
    margin: 0px;
    font-weight: 600;
    font-size: 25px;
    padding: 7px 0px 10px 7px;
    display: inline-block;
}

.user-autologout-modal-content {
    margin-left: 0px !important;
    font-size: 19px;
    padding-bottom: 7px;
}

.user-autologout-modal-footer-btn {
    padding: 11px 26px !important;
    font-size: 17px !important;
    box-shadow: none;
    border-radius: 5px !important;
}

