.template-modal-container {
  .description-wrapper {
    margin-top: 16px;
    textarea {
      min-height: 8vh;
    }
  }
  .form-item-group {
    position: relative;
  }
  .task-options-wrapper {
    margin-top: 26px;
    span {
      font-size: 14px;
      font-weight: bold;
    }
    .label-checkbox-wrapper {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      &:first-of-type {
        margin-top: 16px;
      }

      label {
        font-weight: normal;
      }
      .ss-select {
        width: 235px;
      }
    }
  }
}
.create-edit-modal {
  .text-danger {
    bottom: -20px;
  }
}
.gather-template-overflow{
  max-width: 170px;
}