@import "../../../../styles/branding.scss";

.custom-tab-dropdown {
    background-color: $primaryColor;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 30px;

    .dropdown-container {
        position: relative;
        min-height: 36px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        border-radius: 4px;
        padding-right: 35px;
    }

    .select-label {
        font-size: 15px;
        font-weight: 300;
        min-height: 36px;
        padding: 7px;
        padding-right: 30px;
        color: $gray-600;
    }
    .form-check{
        padding-top: 2px;
        .form-check-input {
            cursor: pointer;
        }
    }

    .dropdown-box {
        position: relative;
        display: flex;
        flex-direction: column;
        border: 1px solid $select-border-color;
        background-color: $primaryColor;
        border-radius: 4px;

        .custom-dropdown-wrapper {
            z-index: 1300;
            background-color: inherit;
            border-bottom: 0.5px solid $gray-400;
            border-radius: 4px;
        }
    }


    .dropdown-tab {
        display: flex;
    }

    .dropdown-tab-link {
        text-align: center;
        padding: 6px;
        cursor: pointer;
        width: 50%;
        border-top: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
    }

    .dropdown-tab-link.active {
        border-bottom: 2px solid $brand-primary-blue-2;
        font-weight: bold;
        color: $brand-primary-blue-2;
    }

    .dropdown-tab-link:last-child {
        border-right: none;
    }

    .dropdown-list-box {
        padding: 6px 0 8px;
    }

    .user-group-chip {
        background: $info-100;
        color: $info;
        border: 1px solid $info;
        display: inline-flex;
        font-size: 0.9em;
        border-radius: 4px;
        padding: 1px 8px;
        margin-top: 5px;
        margin-left: 5px;
        max-width: 150px;
        max-height: 25px;
    }

    .user-chip {
        color: $body-black;
        background: $gray-100;
        border: 1px solid $gray-400;
        font-size: 0.9em;
        border-radius: 4px;
        padding: 1px 8px;
        margin-top: 5px;
        margin-left: 5px;
        max-width: 150px;
        max-height: 25px;
    }

    .chip-label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1;
    }

    .remove-chip {
        cursor: pointer;
        margin-left: 8px;
    }

    .clear-value {
        cursor: pointer;
        margin-right: 15px;
        position: absolute;
        right: 10px;
        top: 18px;
        transform: translateY(-50%);
    }

    .dropdown-icon-button {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 19px;
        transform: translateY(-50%);
    }
    .cursor-not-allowed{
        cursor: not-allowed !important;
        border: 1px solid $disabled-border-color !important;
        background-color: $disabled-background-color
    }
}

.flex-container {
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    cursor: pointer;
}

.user-list-data {
    max-height: 130px;
    overflow-y: auto;
    padding: 0;
    margin: 0;

    .user-list-item {
        font-size: 15px;
        &:hover {
            background-color: $gray-200 !important;
        }
    }

    .user-checkbox {
        display: flex;
        align-items: center;
        width: 100%;
        padding-left: 5px;
        cursor: pointer;
    }

    .user-label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1;
    }
}