@import "../../../../styles/branding.scss";

.preview-section-container {
    padding: 2%;
    margin-top: 2%;
    background-color: white;
    color: $body-black;
}

.preview-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.25px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 10px;
    margin: 0% 1.5%;
}

.mt-1-5 {
    margin-top: 1.5%;
}

.preview-section-container > p {
    font-size: 20px;
    color: #404346;
    padding-bottom: 1.25%;
    border-bottom: 0.25px solid rgba(0, 0, 0, 0.5);
    margin-bottom: 0;
}

.preview-section-container .preview-section-header > p {
    font-size: 20px;
    color: $theme-Sapphire;
    padding-bottom: 1.25%;
    margin-bottom: 0;
    word-break: break-word;
}

.section-delete-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 5%;
}

.section-delete-button:disabled {
    opacity: 0.5;
}

.preview-section-container .question-preview-container {
    margin: 0 1.5%;
}

.question-preview-container label {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
    width: 100%;
    word-wrap: break-word;
    text-align: left;
    font-weight: 400;
}

.preview-question-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
}

.question {
    width: 100%;
    overflow: auto;
}

.question-preview-container label > span:first-child {
    color: $theme-Sapphire;
    margin-right: 12px;
}

.question-preview-container .answer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 30px;
    margin-left: 30px;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.question-preview-container textarea {
    border: 1px solid #898d91;
    border-radius: 2px !important;
    font-size: 16px;
    padding: 7px 13px;
    max-height: 20vh;
}
.question-preview-container textarea.invalid-input {
    margin-bottom: 14px;
}

.question-preview-container .invalid-input {
    border-color: $error !important;
    box-shadow: none;
}

.question-preview-container .non-editable {
    resize: none;
    outline: none;
    cursor: none;
    pointer-events: none;
}

.question-preview-container .non-resizable {
    resize: none;
    margin-top: 25px;
}

.question-preview-container .error-text {
    color: $error !important;
    font-size: 14px;
    bottom: -24px;
    position: absolute;
}

@media (max-width: 1920px) {
    .preview-title {
        font-size: 30px;
    }
}

@media (min-width: 1920px) {
    .preview-title {
        font-size: 40px;
    }
}

.preview-title {
    color: rgba(9, 115, 186, 1);
    font-weight: 500;
    width: 100%;
    height: 100%;
    min-height: 40px;
    padding: 0px;
    border: none;
    border-bottom: none !important;
    resize: none;
    word-wrap: break-word;
}

.preview-description {
    font-size: 16px;
    font-style: italic;
    width: 100%;
    height: 100% !important;
    min-height: 40px;
    padding: 20px 0px 10px 0px;
    vertical-align: bottom;
    border: none;
    border-bottom: none !important;
    color: #212529;
    resize: none;
    outline: none;
    word-wrap: break-word;
}
