@import '../../../styles/branding.scss';
.mobile-section {
    position: relative;
    width: 100% !important;

    label {
        font-size: 14px;
        margin-bottom: 0.25rem;
    }

    .input-cc-mobile,
    .input-cc-mobile-disabled {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $input-border-color;
        border-radius: 2px;
        height: 31px;
        box-sizing: border-box;

        input.form-control {
            padding: 0 0 0 51px;
            border: 0px;
            height: 29px;
            display: none;
        }

        .flag-dropdown {
            background: $gray;
            border: none;
            border-right: 1px solid $gray-400 ;
            border-radius: 0px;
            width: 60px;

            .selected-flag {
                width: 100%;
            }

            .arrow {
                height: 6px;
                width: 6px;
                border: solid $gray-800;
                border-width: 0 1px 1px 0;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                top: 0;
                margin-top: 0px;
                margin-left: 6px;
                &.up{
                    border-bottom-width: 1px;
                }
            }
            
            .country-list {
                top: 21px;
                font-size: 14px;
                left: 1px;
                font-family: 'Mulish', sans-serif;
                width: 290px;
                max-height: 134px;
                .country {
                    padding: 6px 0px 6px 7px;
                }
            }
        }
        
    }
    .input-cc-mobile-disabled {
        .flag-dropdown {
            .selected-flag {
                cursor: not-allowed;
            }
        }
    }   
    .user-mobile {
        border: none;
        width: 70%;
        height: 1.6rem;
        padding: 0.25rem 0.5rem;
        position: absolute;
        top: 7%;
        left: 63px;
    }
    .reset-button{
        position: absolute;
        top: 4%;
        right: 1%;
        background-color: transparent;
        border: none;
        color: #999999;
        font-size: 17px;
        cursor: pointer;
        padding: 0;
        width: 20px;
        height: 20px;

        &:hover {
            color: #D0021B;
        }
    }


}
.user-mobile-disabled {
    border: none;
    width: 70%;
    height: 1.6rem;
    padding: 0.25rem 0.5rem;
    position: absolute;
    top: 7%;
    left: 63px;
    cursor: not-allowed;
}

.user-mobile:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }