@import "../../../../styles/branding.scss";
.download-row-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 14px 28px 14px 14px;
  align-items: center;
  border-bottom: 1px solid $gray-200;
  .file-details {
    display: flex;
    align-items: center;
    &-wrapper {
      margin-left: 11px;
    }

    &-name {
      display: flex;
      font-size: 16px;
      font-weight: 700;
      align-items: center;
      span {
        cursor: pointer;
        &:first-child {
          cursor: initial;
          width: unset;
          max-width: 380px;
        }
        &:not(:first-child) {
          display: flex;
          margin-left: 4px;
        }
      }
    }
    &-date-time {
      font-size: 14px;
      display: flex;
      color: $gray-400;
      font-weight: 700;
      & > span:not(:first-child) {
        margin-left: 4px;
      }
    }
  }
  .actions-wrapper {
    display: flex;
    & > span {
      cursor: pointer;
      border: none;
      svg {
        path {
          fill: $navy;
        }
      }
    }
    & > span:last-child {
      margin-left: 30px;
    }
  }
}
.no-data-text {
  margin: 30px auto !important;
  text-align: center;
  color: $gray-900;
}
