@import "../../../../styles/branding.scss";
.template-modal-container label,
.form-control {
  font-size: 14px;
}

.template-modal-container .form-control {
  font-size: 14px;
  background-image: none !important;
}

.template-modal-container .form-control:valid {
  border-color: #ced4da !important;
}

.template-modal-container label {
  font-weight: bold;
}

.template-modal-container textarea {
  max-height: 15vh;
}

.form-builder-container > header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
}

.form-builder-container .header-top-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.form-builder-container .closeIcon {
  width: 30px;
  opacity: 0.85;
  cursor: pointer;
  padding: 2px 0px 8px 0px;
}

.form-builder-container .closeIcon:hover {
  opacity: 1;
}

.form-builder-container .header-bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}

.form-builder-container .btn-with-icon > svg {
  margin-right: 8px;
}

.form-builder-container .btn-primary:hover > svg path {
  fill: black !important;
}

.form-builder-container .scrollable-loader-wrapper > main > footer > button {
  margin-top: 24px;
}

.form-builder-container .header-bottom-container > span {
  font-size: 20px;
  font-weight: bold;
  max-width: 85%;
  padding-right: 5%;
  display: block;
  text-align: left;
}
.form-builder-container header button {
  width: 120px;
  padding: 6px 0px !important;
  &:hover {
    box-shadow: none !important;
    border-color: unset !important;
  }
}
.form-builder-container > header {
  .preview-button {
    background-color: white !important;
  }
}
.form-builder-container header div {
  display: flex;
}

.form-builder-container > header div .btn-outline-secondary {
  margin-right: 10px;
}

.form-builder-container .scrollable-loader-wrapper > main {
  background-color: rgba(240, 241, 241, 0.6);
  padding: 25px 23px;
  border-top: 0.25px solid rgba(0, 0, 0, 0.15);
  margin-inline: -20px;
  margin-bottom: -10px;
}

.form-builder-container .scrollable-loader-wrapper > main > div {
  box-shadow: 0px 4px 20px rgba(158, 158, 158, 0.3);
  border-radius: 2px;
}

.questionnaire-header-container {
  margin-bottom: 15px;
  position: relative;
  padding: 25px 25px 30px 25px;
  border-top: 5px solid $theme-Sapphire;
  background: white;
}

.questionnaire-header-container-with-more-padding {
  position: relative;
  padding: 1.5% 3.5%;
  border-top: 5px solid $theme-Sapphire;
  background: white;
  text-align: left;
}

.questionnaire-header-container .inner-container {
  width: 100%;
}
.questionnaire-header-container .inner-container .description-container {
  margin-top: 15px;
}

.questionnaire-header-container .field-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.questionnaire-header-container .title {
  color: $theme-Sapphire;
  font-weight: 500;
  width: 90%;
  min-height: 50px;
  height: 100%;
  padding: 0px 6px;
  border: none;
  border-bottom: 1px solid #c4c6c8 !important;
  resize: none;
  word-wrap: break-word;
}

@media (max-width: 1920px) {
  .questionnaire-header-container .title {
    font-size: 28px;
  }
}

@media (min-width: 1920px) {
  .questionnaire-header-container .title {
    font-size: 40px;
  }
}

.questionnaire-header-container .description {
  font-size: 18px;
  font-style: italic;
  width: 90%;
  min-height: 40px;
  height: 100%;
  margin-top: 1px;
  padding: 2px 6px;
  vertical-align: bottom;
  border: none;
  border-bottom: 1px solid #c4c6c8 !important;
  color: $body-black;
  resize: none;
  outline: none;
  word-wrap: break-word;
}

.questionnaire-header-container .feedback {
  width: 100%;
}

.questionnaire-header-container .form-control:valid {
  border-color: transparent;
  background-image: none !important;
}

.questionnaire-header-container .form-control:invalid {
  border-color: transparent;
  background-image: none !important;
}

.questionnaire-header-container input:focus {
  outline: none;
}

.questionnaire-header-container .invalid-feedback {
  font-size: 14px;
}

.questionnaire-header-container p {
  font-size: 16px;
  position: absolute;
  bottom: 15px;
  right: 2%;
}

.questionnaire-header-container p i {
  color: $error;
  font-size: 27px;
}

.cq-error-red {
  color: $error !important;
}

.questionnaire-header-container input::-webkit-input-placeholder {
  color: #c4c6c8;
}

.questionnaire-header-container .error-text {
  font-size: 14px;
  position: absolute;
  margin-bottom: 0;
  color: $error;
  left: 0;
  z-index: 100;
  right: 0;
  bottom: -19px;
  display: flex;
}

.form-footer {
  padding-bottom: 25px;
}
.form-builder-container .form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
  .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
  }
}
.draggableItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  margin: auto;
  color: #000;
  border-radius: 0px;
  cursor: pointer;
  border-bottom: 1px solid #a6a9ac;
}

.draggableItem:last-child {
  border-bottom: none;
}

.no-horizontal-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.no-horizontal-padding > div {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.section-details {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.section-details-sectionName {
  display: inline-block;
  width: 100%;
  color: #212529;
  font-weight: 700;
  font-size: 16px !important;
}

.section-details-sectionCount {
  color: #6b7075;
  font-weight: 500;
  font-size: 14px;
  .template-modal-container {
    .description-wrapper {
      margin-top: 16px;
    }
    .task-options-wrapper {
      margin-top: 26px;
      span {
        font-size: 14px;
        font-weight: bold;
      }
      .label-checkbox-wrapper {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        &:first-of-type {
          margin-top: 16px;
        }

        label {
          font-weight: normal;
        }
        .ss-select {
          width: 52%;
        }
      }
    }
  }
}
.error-text{
  font-weight: 400 !important;
}