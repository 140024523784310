.custom-questions-page-title {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.questionHeading {
  color: #212529;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 20px !important;
  text-align: left;
  font-weight: 700;
}

.horizontal-divider {
  margin: 4px 0px 12px 0px;
  border-color: rgba(0, 0, 0, 0.2);
}

.new-main-content h4 {
  color: #1a8fbf;
}

.templates-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: 20px;
  margin-left: -10px;
}

.template-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 224px;
  margin: 16px;
}

.template {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  width: 100%;
  height: 172px !important;
  background-color: #e6ebf0 !important;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 4px;
  padding: 0px;
  cursor: pointer;
}

.template:hover {
  background-color: #dfe9f3 !important;
  border: 1px solid rgba(0, 0, 0, 0.275);
}

.scratch {
  background-color: #fff !important;
  cursor: pointer;
}

.create-new-card-container {
  border: 1.5px dashed #05386b !important;
  background-color: white !important;
  border-radius: 4px;
}

.create-new-card-container > span {
  font-size: 16px;
  font-weight: 500;
  color: #212529;
}

.create-new-card-container:hover > span {
  text-decoration: underline;
}

.create-new-card-container > svg {
  margin-bottom: 18px;
}

.template-create-new-button {
  color: #0973ba;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 6px 12px;
  gap: 8px;
  width: 114px;
  height: 38px;
  background-color: #fff;
  border: 1px solid #0973ba;
  border-radius: 2px;
}

.template-create-new-button:hover {
  color: #fff;
  background-color: #0973ba;
}

.template-more-options {
  height: 30px;
  background-color: transparent !important;
  border: none;
  position: absolute !important;
  bottom: 0px;
  right: 0px;
  outline: none;
}

.template-more-options:hover {
  background-color: rgba(0 0, 0, 0.08) !important;
}

.template-more-options .btn-primary.dropdown-toggle {
  border-color: transparent !important;
  outline: none;
}
.template-more-options .dropdown-toggle::after {
  display: none;
}
.template-more-options-menu {
  position: absolute !important;
  top: -10px;
  margin: -8px 0px !important;
  left: -144px !important;
  /*need to update this*/
  width: 160px;
  border: 1px solid #aeaeae;
  background: #fff;
  padding: 0px;
  z-index: 1000;
  border-radius: 2px;
}

.template-menu-item {
  width: 148px;
  height: 32px;
  font-size: 16px;
  cursor: pointer;
}

.template-menu-item:hover {
  width: 100%;
  height: 32px;
  font-size: 16px;
  cursor: pointer;
  background-color: #f0f1f1 !important;
}

.template-menu-item-disabled {
  width: 148px;
  height: 32px;
  font-size: 16px;
  opacity: 0.5;
  cursor: no-drop !important;
}

.template-menu-item-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
  cursor: pointer;
  pointer-events: none;
}

.delete-confirmation-note {
  font-size: 14px;
  font-weight: bold;
  padding-right: 5px;
}

.red {
  color: #cc4a43;
}

.template .dropdown .btn-primary {
  background-color: transparent;
  padding: 0px 6px 3px 6px !important;
  border: none;
  box-shadow: none !important;
  outline: none;
}

.template .dropdown .btn-primary:focus {
  outline: none;
}

.template-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.template-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.template-name {
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.template-description {
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.default-template-button {
  background-color: transparent;
  border: none;
}

.template-modal-container .modal-dialog {
  max-width: 500px !important;
}

.template-modal-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
}

.template-modal-row-label {
  font-size: 14px;
}

.template-modal-row-input {
  border: 1px solid #898d91;
  border-radius: 2px;
  padding: 6px 10px;
  font-size: 14px;
}

.create-template-modal-title {
  font-size: 20px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #212529;
}

.template-modal-submit-button {
  margin: 4px !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 88px;
  height: 38px;
  color: #fff;
  background-color: #0973ba;
  border: 1px solid #0973ba;
  border-radius: 2px;
}

.template-modal-cancel-button {
  margin: 4px !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 77px;
  height: 38px;
  color: #202223;
  background-color: #f0f1f1;
  border: 1px solid #898d91;
  border-radius: 2px;
}

.merge-section-modal-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 25px 0px;
  min-height: 150px;
}

.merge-section-modal-content > div {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.merge-section-modal-content > div > label {
  color: #000;
  font-weight: 500;
}

.merge-section-modal-content > div > div > div {
  border: 1px solid #898d91;
  border-radius: 4px !important;
}

.cq-merge-sections-dropdown {
  margin: 0px 10px;
  width: 75%;
}

.cq-merge-sections-dropdown-control {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.cq-merge-sections-dropdown .Select-input {
  display: none !important;
}

.cq-merge-sections-dropdown .Select-clear-zone {
  display: none;
}
.source-dd .Select-option {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.cq-merge-sections-dropdown .Select-multi-value-wrapper div:not(:first-child) {
  display: none;
}

.cq-merge-sections-dropdown-label {
  width: 100%;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 7px 0px 0px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.dd-option {
  max-width: 82%;
}
.cq-merge-sections-dropdown-checkbox {
  margin-right: 10px;
}
.cq-merge-sections-dropdown .Select-menu-outer {
  border: 1px solid #898d91;
  max-height: 100px;
  overflow-y: auto;
}
.cq-merge-sections-dropdown .Select-menu {
  max-height: unset;
}
